// login component
import React, { Component, useState } from "react";
import { useHistory } from "react-router-dom";

import Backdrop from "../components/ui/Backdrop";
import Modal from "../components/ui/Modal";
import Card from "../components/ui/Card";

function LoginPage(props) {
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  const history = useHistory();

  function modalSubmit() {
    setLoginModalIsOpen(!setLoginModalIsOpen);
    history.replace("/drugsearch");
    // console.log("Confirm!");
  }

  function confirmHandler() {
    console.log("Confirm!");
    console.log(email);
    modalSubmit();
  }

  return (
    // login page with user name and password fields
    // login button
    <div>
      <Modal
        headerName="Login"
        onConfirm={confirmHandler}
        component={
          <form onSubmit={confirmHandler}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 font-bold mb-2"
              >
                Username (Email)
              </label>
              <input
                type="email"
                id="email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-gray-700 font-bold mb-2"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
          </form>
        }
      ></Modal>
      {loginModalIsOpen && <Backdrop onClick={confirmHandler} />}
    </div>
  );
}

export default LoginPage;

// <div className="fixed z-10 inset-0 overflow-y-auto">
//   <div className="flex items-center justify-center min-h-screen">
//     <div className="bg-white rounded-lg overflow-hidden w-full sm:w-96">
//       <div className="px-6 py-4">
// <form onSubmit={confirmHandler}>
//   <div className="mb-4">
//     <label
//       htmlFor="email"
//       className="block text-gray-700 font-bold mb-2"
//     >
//       Username (Email)
//     </label>
//     <input
//       type="email"
//       id="email"
//       className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//       value={email}
//       onChange={handleEmailChange}
//     />
//   </div>
//   <div className="mb-4">
//     <label
//       htmlFor="password"
//       className="block text-gray-700 font-bold mb-2"
//     >
//       Password
//     </label>
//     <input
//       type="password"
//       id="password"
//       className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//       value={password}
//       onChange={handlePasswordChange}
//     />
//   </div>
// </form>
//       </div>
//     </div>
//   </div>
// </div>;

import { useContext, useState, useEffect } from "react";
import DrugFavoritesContext from "../store/drug-favorites-context";

import classes from "./layout/TableLayout";
import Card from "./ui/Card";

function FDARecord(ndc) {
  // console.log(ndc);
  const drugFavCTX = useContext(DrugFavoritesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedDrugs, setLoadedDrugs] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    fetch(
      `https://three40b-drug-info-api.onrender.com/fda/product/${ndc.ndc}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setLoadedDrugs(data);
      });
  }, [ndc]);

  const drug = loadedDrugs;
  console.log(drug);

  if (isLoading) {
    return <p>FDA NDC Directory Loading...</p>;
  }

  const drugIsFavorite = drugFavCTX.itemIsFavorite(drug.spl_id);

  function toggleDrugFavoriteStatusHandler() {
    if (drugIsFavorite) {
      drugFavCTX.removeFavorite(drug.spl_id);
    } else {
      drugFavCTX.addFavorite({
        id: drug.spl_id,
        product_ndc: drug.product_ndc,
      });
    }
  }
  // console.log(drug.route != undefined || drug.route != null, drug.route);

  function nullCheck(item) {
    if (item === null || item === undefined) {
      return false;
    } else {
      return true;
    }
  }

  // console.log(nullCheck(drug.route));

  return (
    <Card>
      <section>
        <h2>
          <strong>FDA NDC Directory</strong>
        </h2>
        {drug.fda.map((d) => {
          return (
            <div key={d.spl_id}>
              <h2>
                {d.generic_name} {d.dosage_form} ({d.brand_name})
              </h2>
              <div>
                {
                  nullCheck(d.route) &&
                    d.route.map((r, index) => {
                      return <p key={index}>{r}</p>;
                    })
                  // <p>Not null or undefined</p>
                }
              </div>
              {d.openfda && d.openfda.manufacturer_name
                ? d.openfda.manufacturer_name.map((m, index) => {
                    return <p key={index}>{m}</p>;
                  })
                : null}
              <h2>{d.product_ndc} (NDC 9)</h2>
              {nullCheck(d.active_ingredients) ? (
                <table className={classes.table}>
                  <thead>Active Ingredients</thead>
                  <tr>
                    <th>Name</th>
                    <th>Strength</th>
                  </tr>
                  <tbody>
                    {d.active_ingredients.map((a, index) => {
                      return (
                        <tr key={index}>
                          <td>{a.name}</td>
                          <td>{a.strength}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}
              {d.packaging ? (
                <table>
                  <thead>Packaging</thead>
                  <tr>
                    <th>Package NDC</th>
                    <th>Description</th>
                    <th>Marketing Start Date</th>
                  </tr>
                  <tbody>
                    {d.packaging.map((p, index) => {
                      return (
                        <tr key={index}>
                          <td>{p.package_ndc}</td>
                          <td>{p.description}</td>
                          <td>{p.marketing_start_date}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}
              <div>
                {d.pharm_class ? (
                  <table>
                    <thead>Pharmacologic Class(es)</thead>
                    {d.pharm_class.map((c, index) => {
                      return (
                        <tr className={classes.table} key={index}>
                          {c}
                        </tr>
                      );
                    })}
                  </table>
                ) : null}
              </div>
            </div>
          );
        })}
      </section>
    </Card>
  );
}

export default FDARecord;

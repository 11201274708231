import Button from "./Button";
import AltButton from "./AltButton";

function Modal(props) {
  // console.log(props);
  return (
    <div className="modal">
      <h2>{props.headerName}</h2>
      <p>{props.paragraph}</p>
      <div>{props.component}</div>
      <div>
        {{
          btn1: <Button onClick={props.onConfirm} />,
          btnAlt: <AltButton />,
        }[props.btn] || (
          <div>
            <Button />
            <AltButton />
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;

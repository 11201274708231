import { useContext } from "react";

import SearchContext from "../store/drug-search-context";

import FDARecord from "../components/FDARecord";
import MDRPRecord from "../components/MDRPRecord";
import SearchBar from "../components/ui/SearchBar";
import Card from "../components/ui/Card";
import CMSRecord from "../components/CMSRecord";

let ndc = "";

function DrugSearch() {
  const searchCTX = useContext(SearchContext);

  ndc = searchCTX.search;

  return (
    <div>
      <SearchBar />
      {ndc ? (
        <section>
          <h2>Results</h2>
          <FDARecord ndc={ndc} />
          <MDRPRecord ndc={ndc} />
          <CMSRecord ndc={ndc} />
        </section>
      ) : (
        <Card>Enter an 11-digit NDC above to get results</Card>
      )}
    </div>
  );
}

export default DrugSearch;

import { Route, Switch } from "react-router-dom";

import DrugSearch from "./pages/DrugSearch";
import DrugFavorites from "./pages/DrugFavorites";
import About from "./pages/About";
import Layout from "./components/layout/Layout";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";

function App() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route path="/drugfavorites">
          <DrugFavorites />
        </Route>
        <Route path="/drugsearch">
          <DrugSearch />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;

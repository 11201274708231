import { useContext, useState, useEffect } from "react";
import DrugFavoritesContext from "../store/drug-favorites-context";

import classes from "./layout/TableLayout";
import Card from "./ui/Card";

function MDRPRecord(ndc) {
  // console.log(ndc);
  const drugFavCTX = useContext(DrugFavoritesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedDrugs, setLoadedDrugs] = useState([]);

  useEffect(() => {
    fetch(
      `https://three40b-drug-info-api.onrender.com/mdrp/product/${ndc.ndc}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return {
            error: true,
            msg: "problem retriving data from MDRP data!!!!",
          };
        }
      })
      .then((data) => {
        setIsLoading(false);
        setLoadedDrugs(data);
      });
  }, [ndc.ndc]);

  const drug = loadedDrugs;
  console.log(drug);

  if (isLoading) {
    return (
      <section>
        <p>MRDP Data Loading...</p>
      </section>
    );
  }

  const drugIsFavorite = drugFavCTX.itemIsFavorite(drug.id);

  function toggleDrugFavoriteStatusHandler() {
    if (drugIsFavorite) {
      drugFavCTX.removeFavorite(drug.id);
    } else {
      drugFavCTX.addFavorite({
        id: drug.id,
        product_ndc: drug.product_ndc,
      });
    }
  }
  function nullCheck(item) {
    if (item === null || item === undefined) {
      return false;
    } else {
      return true;
    }
  }

  // console.log(nullCheck(drug), drug["mdrp-error"]);

  return (
    <Card>
      {drug.error !== true ? (
        <section>
          <div className={classes.header}>
            <h2>
              <strong>Medicaid Drug Rebate Program</strong>
            </h2>
          </div>

          {nullCheck(drug.mdrp) && drug["mdrp-error"] !== null ? (
            drug.mdrp.map((d) => {
              return (
                <div key={d.ndc11}>
                  <h2>
                    {d.fda_name} {d.unit_type}
                  </h2>

                  <table>
                    <thead>Products in MDRP</thead>
                    <tr>
                      <th>Product NDC</th>
                      <th>Units per Package</th>
                    </tr>
                    <tbody>
                      <tr>
                        <td>
                          {d.ndc1}-{d.ndc2}-{d.ndc3}
                        </td>
                        <td>{d.units_per_package_size}</td>
                      </tr>
                    </tbody>
                  </table>

                  <p>COD Status: {d.cod_status}</p>
                </div>
              );
            })
          ) : (
            <p>{drug["mdrp-error"]}</p>
          )}
        </section>
      ) : (
        <p>{drug.msg}</p>
      )}
    </Card>
  );
}

export default MDRPRecord;

import { createContext, useState } from "react";

const DrugFavoritesContext = createContext({
  drugFavorites: [],
  totalFavorites: 0,
  addFavorite: (favoriteDrug) => {},
  removeFavorite: (drugId) => {},
  itemIsFavorite: (drugId) => {},
});

export function DrugFavoritesContextProvider(props) {
  const [userDrugFavorites, setUserDrugFavorites] = useState([]);

  function addFavoriteHandler(favoriteDrug) {
    setUserDrugFavorites((prevUserDrugFavorites) => {
      return prevUserDrugFavorites.concat(favoriteDrug);
    });
  }

  function removeFavoriteHandler(drugId) {
    setUserDrugFavorites((prevUserDrugFavorites) => {
      return prevUserDrugFavorites.filter((drug) => drug.Id !== drugId);
    });
  }

  function itemIsFavoriteHandler(drugId) {
    return userDrugFavorites.some((drug) => drug.Id === drugId);
  }

  const context = {
    favorites: userDrugFavorites,
    totalFavorites: userDrugFavorites.length,
    addFavorite: addFavoriteHandler,
    removeFavorite: removeFavoriteHandler,
    itemIsFavorite: itemIsFavoriteHandler,
  };

  return (
    <DrugFavoritesContext.Provider value={context}>
      {props.children}
    </DrugFavoritesContext.Provider>
  );
}

export default DrugFavoritesContext;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { DrugFavoritesContextProvider } from "./store/drug-favorites-context";
import { SearchContextProvider } from "./store/drug-search-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DrugFavoritesContextProvider>
    <SearchContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SearchContextProvider>
  </DrugFavoritesContextProvider>
);

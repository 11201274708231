import { Link } from "react-router-dom";
import { useContext } from "react";

import classes from "./MainNavigation.module.css";
import DrugFavoritesContext from "../../store/drug-favorites-context";

function MainNavigation() {
  const drugFavCtx = useContext(DrugFavoritesContext);

  return (
    <header className={classes.header}>
      <div className={classes.logo}>Logo</div>
      <nav>
        <ul>
          <li>
            <Link to="/drugsearch">Drug Search</Link>
          </li>
          <li>
            <Link to="/drugfavorites">
              Drug Favorites
              <span className={classes.badge}>{drugFavCtx.totalFavorites}</span>
            </Link>
          </li>
          <li>
            <Link to="/About">About</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default MainNavigation;

import { createContext, useState } from "react";

const SearchContext = createContext({
  searchTerm: "",
});

export function SearchContextProvider(props) {
  const [searchTerm, setSearchTerm] = useState("");

  function addTermHandler(term) {
    return setSearchTerm(term);
  }

  const context = {
    search: searchTerm,
    searchTermLength: searchTerm.length,
    addTerm: addTermHandler,
    // itemIsFavorite: itemIsFavoriteHandler,
  };

  return (
    <SearchContext.Provider value={context}>
      {props.children}
    </SearchContext.Provider>
  );
}

export default SearchContext;

import { useRef, useContext } from "react";

import SearchContext from "../../store/drug-search-context";
import Card from "../ui/Card";
import classes from "../layout/Form.module.css";

function SearchBar() {
  const searchCTX = useContext(SearchContext);

  const searchTerm = useRef();

  function submitHandler(event) {
    event.preventDefault();

    const enteredSearchTerm = searchTerm.current.value;

    // console.log(enteredSearchTerm);

    searchCTX.addTerm(enteredSearchTerm);

    // console.log(searchCTX);
  }

  return (
    <Card>
      <form className={classes.form} onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor="ndc_search">Drug Search</label>
          <input type="text" required id="ndc_search" ref={searchTerm} />
        </div>
        <div>
          <button className={classes.actions}>Search</button>
        </div>
      </form>
    </Card>
  );
}

export default SearchBar;

import { useState } from "react";
import { useHistory } from "react-router-dom";

import Backdrop from "../components/ui/Backdrop";
import Modal from "../components/ui/Modal";

function LandingPage(props) {
  const [landingModalIsOpen, setLandingModalIsOpen] = useState(true);
  const history = useHistory();

  function confirmHandler() {
    setLandingModalIsOpen(!setLandingModalIsOpen);
    history.replace("/drugsearch");
    // console.log("Confirm!");
  }

  let paragraph =
    "Welcome to the 340B drug information portal.  This is site was intended to give folks who work in 340B operations addtional information to aid in managing day to day 340B activities.  This site is undergoing upgrades but in the meantime feel free to go and search for drug information that could help your day to day.";

  return (
    <div>
      {landingModalIsOpen && (
        <Modal
          onConfirm={confirmHandler}
          btn={"btn1"}
          paragraph={paragraph}
        ></Modal>
      )}
      {landingModalIsOpen && <Backdrop onClick={confirmHandler} />}
    </div>
  );
}

export default LandingPage;

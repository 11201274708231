import { useContext } from "react";

import DrugFavoritesContext from "../store/drug-favorites-context";

function DrugFavorites() {
  const drugFavCtx = useContext(DrugFavoritesContext);

  let content;
  if (drugFavCtx.totalFavorites === 0) {
    content = <p>You have not favorited any drugs.</p>;
  } else {
    content = <p>Favorites</p>;
  }
  return (
    <div>
      <section>
        <h1>My Favorites</h1>
        <p>{content}</p>
      </section>
    </div>
  );
}

export default DrugFavorites;

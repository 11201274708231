import { useContext, useState, useEffect } from "react";
import DrugFavoritesContext from "../store/drug-favorites-context";

import classes from "./layout/TableLayout";
import Card from "./ui/Card";

function CMSRecord(ndc) {
  const drugFavCTX = useContext(DrugFavoritesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedDrugs, setLoadedDrugs] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    fetch(
      `https://three40b-drug-info-api.onrender.com/cms/product/${ndc.ndc}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setLoadedDrugs(data);
      });
  }, [ndc.ndc]);

  const drug = loadedDrugs;
  console.log(drug);

  if (isLoading) {
    return <p>CMS ASP-NDC Crosswalk Loading...</p>;
  }

  const drugIsFavorite = drugFavCTX.itemIsFavorite(drug.spl_id);

  function toggleDrugFavoriteStatusHandler() {
    if (drugIsFavorite) {
      drugFavCTX.removeFavorite(drug.spl_id);
    } else {
      drugFavCTX.addFavorite({
        id: drug.spl_id,
        product_ndc: drug.product_ndc,
      });
    }
  }
  function nullCheck(item) {
    if (item === null || item === undefined) {
      return false;
    } else {
      return true;
    }
  }
  return (
    <Card>
      <section>
        <div className={classes.header}>
          <h2>
            <strong>CMS ASP-NDC Crosswalk</strong>
          </h2>
        </div>
      </section>
      {nullCheck(drug) && nullCheck(drug["cms-error"]) === false ? (
        <table className={classes.table}>
          {/* <thead>CMS ASP-NDC Crosswalk</thead> */}
          <tr>
            <th>NDC</th>
            <th>HCPCS Code</th>
            <th>HCPCS Dose</th>
            <th>Short Description</th>
            <th>Labeler Name</th>
            <th>Drug Name</th>
            <th>Package Size</th>
            <th>Billing Units Per Package</th>
          </tr>
          <tbody>
            {drug.cms_asp_ndc_crosswalk.map((d, index) => {
              return (
                <tr key={index}>
                  <td>{d["NDC2"]}</td>
                  <td>{d["_2022_CODE"]}</td>
                  <td>{d['HCPCS dosage']}</td>
                  <td>{d["Short Description"]}</td>
                  <td>{d['LABELER NAME']}</td>
                  <td>{d['Drug Name']}</td>
                  <td>{d["PKG SIZE"]}</td>
                  <td>{d["BILLUNITSPKG"]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>{drug["cms-error"]}</p>
      )}
    </Card>
  );
}

export default CMSRecord;
